import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import popup from "../../../assests/popup.png";
import { FaArrowLeft } from 'react-icons/fa6';
import { Controller, useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { useGetUserByIdQuery, useUpdateUserMutation } from "../../../redux/apis/user";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import { Spinner } from "react-bootstrap";

const EditSalesman = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm();

  const { data: userData, isLoading: gettingUser } = useGetUserByIdQuery(id);
  const [updateUser, { isLoading: isUpdating, isSuccess: isUpdateSuccess }] = useUpdateUserMutation();

  useEffect(() => {
    if (userData) {
      setValue('fullName', userData?.data?.fullName);
      setValue('phoneNumber', userData?.data?.phoneNumber);
      setValue('email', userData?.data?.email);
      setValue('commissionA', userData?.data?.commissionA);
      setValue('commissionB', userData?.data?.commissionB);
    }
  }, [userData]);

  const onSubmit = data => {
    updateUser({ ...data, id });
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      reset();
      navigate('/admin/salesman');
      toast.success("Salesman details updated successfully");
    }
  }, [isUpdateSuccess]);


  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/>
        <SideBar/> */}
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg" style={{ padding: '10px 10px 50px' }}>
              <div className="row">
                <div className="col-sm-4 col-3 main-heading-1">
                  <h2><a href="/admin/salesman" className="btn back"><FaArrowLeft /></a>Edit Salesman</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="fullName"
                      control={control}
                      rules={{
                        required: "Full Name is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label={"Full Name"}
                          placeholder="Enter Full Name"
                          error={errors?.fullName?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: "Phone Number is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label={"Phone Number"}
                          placeholder="Enter Phone Number"
                          error={errors?.phoneNumber?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Email is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label={"Email Address"}
                          placeholder="Enter Email Address"
                          error={errors?.email?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  {/* <div className="col-sm-5 ofset-7">
                    <Controller
                      name="commission"
                      control={control}
                      defaultValue={"30"}
                      rules={{
                        required: "Commission is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label={"Commission"}
                          placeholder="Enter Commission"
                          error={errors?.commission?.message}
                          {...field}
                        />
                      )}
                    />
                  </div> */}
                  
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="commissionA"
                      control={control}
                      defaultValue={"25"}
                      rules={{
                        required: "Commission A Customer type is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label={"Commission A"}
                          placeholder="Enter Commission Customer A type"
                          error={errors?.commission?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="commissionB"
                      control={control}
                      defaultValue={"30"}
                      rules={{
                        required: "Commission B Customer type is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label={"Commission B"}
                          placeholder="Enter Commission Customer B type"
                          error={errors?.commission?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="password"
                      control={control}
                      // rules={{
                      //   required: "Password is required"
                      // }}
                      render={({ field }) => (
                        <InputField
                          label={"Password"}
                          placeholder="Enter New Password"
                          error={errors?.password?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-12">
                    <Button
                      loading={gettingUser}
                      text="Update Salesman"
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
}
export default EditSalesman;