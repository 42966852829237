import moment from "moment";
import { FRIDAY_WORKING_HOURS, WORKING_HOURS } from "./constants";

let PAYROLL_START_DATE = "21"
let PAYROLL_END_DATE = "20"

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const calculate_salary_percent = (duration, day) => {
    if (duration && day) {
        let percent = ((duration / (day === 5 ? FRIDAY_WORKING_HOURS : WORKING_HOURS)) * 100).toFixed(2)
        return percent
    }

    return 0.00
}

export const format_date = value => {
    let date = new Date(value)
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    return (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
}

export const getLastTwoYears = () => {

    let years = [2024, 2023]

    return years

}

export const getMonths = () => {

    const months = ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    return months

}
export const getYears = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 5 }, (_, i) => (currentYear - i).toString()); // Last 5 years
  };
export const getDaysLabel = () => {

    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']

    return days

}

export const amountFormatter = amount => {
    return amount?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
}

export const getWeeks = () => {

    const weeks = [
        {
            label: "Week 1 - 7",
            start: 1,
            end: 7
        },
        {
            label: "Week 8 - 14",
            start: 8,
            end: 14
        },
        {
            label: "Week 15 - 21",
            start: 15,
            end: 21
        },
        {
            label: "Week 22 - 28",
            start: 22,
            end: 28
        },
        {
            label: "Week 29 - 35",
            start: 29,
            end: 35
        },
        {
            label: "Week 36 - 42",
            start: 36,
            end: 42
        },
        {
            label: "Week 43 - 49",
            start: 43,
            end: 49
        },
        {
            label: "Week 50 - 52",
            start: 50,
            end: 52
        },
    ]

    return weeks

}


export const convertUTCDateToLocalDate = (date) => {
    var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
}

export const monthlyIntervalDate = (m, y) => {

    const d = getMonthYear()
    let month = d.month
    let currentYear = d.year

    if (m) {
        month = m
    }

    if (y) {
        currentYear = y
    }

    let monthForSecond = month + 1
    let yearForSecond = currentYear

    if (month == 0) {
        month = 12
        currentYear = currentYear - 1
    } else if (month == 12) {
        monthForSecond = 1
        yearForSecond = currentYear + 1
    }

    let firstMonth = moment(`${currentYear}-${month}`).daysInMonth() - 20
    firstMonth = Array.from(Array(firstMonth), (_, i) => moment(`${currentYear}-${month}-${String(i + 21).padStart(2, '0')}`).format("DD-MM-YYYY"))
    let secondMonth = Array.from(Array(20), (_, i) => moment(`${yearForSecond}-${monthForSecond}-${String(i + 1).padStart(2, '0')}`).format("DD-MM-YYYY"))

    let monthlyDates = [...firstMonth, ...secondMonth]

    return {
        from: `${currentYear}-${month}-${PAYROLL_START_DATE}`,
        to: `${yearForSecond}-${monthForSecond}-${PAYROLL_END_DATE}`,
        monthlyDates
    }

}

// export const monthlyIntervalDate = (m, y) => {
//     const d = getMonthYear();
//     let month = d.month;
//     let currentYear = d.year;

//     if (m) {
//         month = m;
//     }

//     if (y) {
//         currentYear = y;
//     }

//     // Ensure month is in the correct format (1-12)
//     month = month < 10 ? `0${month}` : `${month}`;

//     // Get the last day of the month
//     const lastDayOfMonth = moment(`${currentYear}-${month}`).daysInMonth();

//     // Generate an array of dates from the 1st to the last day of the month
//     const monthlyDates = Array.from(
//         { length: lastDayOfMonth },
//         (_, i) => moment(`${currentYear}-${month}-${String(i + 1).padStart(2, '0')}`).format("DD-MM-YYYY")
//     );

//     return {
//         from: `${currentYear}-${month}-01`,
//         to: `${currentYear}-${month}-${lastDayOfMonth}`,
//         monthlyDates
//     };
// };

export const getMonthYear = () => {

    const d = new Date();

    let date = d.getDate()
    let month = d.getMonth()
    let year = d.getFullYear()

    if (date >= PAYROLL_START_DATE) {
        month += 1
    }

    return { month, year }

}

export const isPdf = (fileName) => {
    var ext = fileName.substr(fileName.lastIndexOf('.') + 1);
    return ext === "pdf"
}

export const CURRENCY = "SEK"